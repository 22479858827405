
import { defineComponent, onMounted, nextTick } from "vue";
import { SwapperComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "kt-page-title",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {},
  setup() {
    onMounted(() => {
      nextTick(() => {
        SwapperComponent.createInsance('[data-kt-swapper="true"]');
      });
    });
  },
});
