import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "kt_aside",
  class: "shadow d-sm-block",
  style: {"background-color":"white"}
}
const _hoisted_2 = { class: "svg-icon svg-icon-2x" }
const _hoisted_3 = ["hidden"]
const _hoisted_4 = {
  class: "row aside-logo flex-column-auto pt-4 px-9",
  id: "kt_aside_logo"
}
const _hoisted_5 = { class: "svg-icon svg-icon-2x" }
const _hoisted_6 = { class: "aside-menu flex-column-fluid px-3 px-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenuLogo = _resolveComponent("KTMenuLogo")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isToggle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "btn btn-icon btn-circle btn-active-light-primary mt-7 ms-5",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggleShow && _ctx.handleToggleShow(...args)))
          }, [
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_inline_svg, { src: "media/Toggle-Left.svg" })
            ])
          ]),
          _createElementVNode("div", {
            class: "aside-menu flex-column-fluid ms-1 px-1",
            onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMouseEnter()))
          }, [
            _createVNode(_component_KTMenuLogo)
          ], 32)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "kt_aside",
      class: "aside bg-white shadow",
      "data-kt-drawer": "true",
      "data-kt-drawer-name": "aside",
      "data-kt-drawer-activate": "{default: true, lg: false}",
      "data-kt-drawer-overlay": "true",
      "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
      "data-kt-drawer-direction": "start",
      "data-kt-drawer-toggle": "#kt_aside_toggle",
      hidden: _ctx.isToggle,
      onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleMouseLeave()))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.reloadPage && _ctx.reloadPage(...args))),
          alt: "Logo",
          src: "assets/images/newlogobem.png",
          class: "max-h-50px logo-default col-9",
          style: {"cursor":"pointer"}
        }),
        _createElementVNode("img", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.reloadPage && _ctx.reloadPage(...args))),
          alt: "Logo",
          src: "assets/images/newlogobem.png",
          class: "max-h-50px logo-minimize col-9"
        }),
        _createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleToggleHide && _ctx.handleToggleHide(...args))),
          class: "btn btn-icon btn-circle btn-active-light-primary ms-n2 me-1"
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/Toggle-Right.svg" })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_KTMenu)
      ])
    ], 40, _hoisted_3)
  ], 64))
}