
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import MenuComponent from "@/components/menu/MenuComponent.vue";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  components: {
    MenuComponent,
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },
  data: () => ({
    hoverByClickDb: "",
    hoverByClickOther: "",
    index1: "",
    index2: "",
    textColorDb: "",
    textColorOther: "",
  }),
  methods: {
    subItemChange() {
      this.hoverByClickOther = "";
      this.textColorOther = "";
    },
    hoverListDb() {
      this.hoverByClickDb = "background-color: AliceBlue;";
      this.textColorDb = "color: #3699ff";
    },
    hoverListOther(k, j) {
      this.index1 = j;
      this.index2 = k;
      this.hoverByClickDb = "";
      this.hoverByClickOther = "background-color: AliceBlue;";

      this.textColorDb = "";
      this.textColorOther = "color: #3699ff";
    },
  },
});
