import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "page d-flex flex-row flex-column-fluid",
  style: {"background-color":"#f4f4f4"}
}
const _hoisted_2 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid fs-6 pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTPurchase = _resolveComponent("KTPurchase")!
  const _component_KTDrawerMessenger = _resolveComponent("KTDrawerMessenger")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateAccountModal = _resolveComponent("KTCreateAccountModal")!
  const _component_KTNewCardModal = _resolveComponent("KTNewCardModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAside, {
            key: 0,
            onContentLeft: _ctx.contentLeft,
            onContentRight: _ctx.contentRight,
            "compact-logo": _ctx.themeCompactLogo,
            "default-logo": _ctx.themeDefaultLogo
          }, null, 8, ["onContentLeft", "onContentRight", "compact-logo", "default-logo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        id: "kt_wrapper",
        class: _normalizeClass(_ctx.isClassHeader)
      }, [
        _createVNode(_component_KTHeader),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            id: "kt_content_container",
            class: _normalizeClass({
            'container-fluid': _ctx.contentWidthFluid,
            container: !_ctx.contentWidthFluid,
          })
          }, [
            _createVNode(_component_router_view)
          ], 2)
        ]),
        _createVNode(_component_KTFooter)
      ], 2)
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTPurchase),
    _createVNode(_component_KTDrawerMessenger),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateAccountModal),
    _createVNode(_component_KTNewCardModal)
  ], 64))
}