
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-notification-menu",
  components: {},
  setup() {
    const notifications = [
      {
        icon: "media/icons/duotune/abstract/abs027.svg",
        title: "Developer Library added",
        info: "2 hours ago",
        color: "warning",
      },
      {
        icon: "media/icons/duotune/finance/fin002.svg",
        title: "Credit card expired",
        info: "1 day ago",
        color: "danger",
      },
      {
        icon: "media/icons/duotune/ecommerce/ecm002.svg",
        title: "New Order placed",
        info: "5 days ago",
        color: "primary",
      },
      {
        icon: "media/icons/duotune/communication/com006.svg",
        title: "New user library added",
        info: "2 weeks ago",
        color: "info",
      },
      {
        icon: "media/icons/duotune/general/gen030.svg",
        title: "Mark Simpson liked your page",
        info: "1 month ago",
        color: "primary",
      },
      {
        icon: "media/icons/duotune/files/fil016.svg",
        title: "New project saved",
        info: "2 hours ago",
        color: "success",
      },
      {
        icon: "media/icons/duotune/coding/cod001.svg",
        title: "Review privacy policy",
        info: "1 day ago",
        color: "warning",
      },
      {
        icon: "media/icons/duotune/general/gen027.svg",
        title: "Order deleted",
        info: "5 days ago",
        color: "danger",
      },
      {
        icon: "media/icons/duotune/arrows/arr029.svg",
        title: "System update reminder",
        info: "2 weeks ago",
        color: "primary",
      },
      {
        icon: "media/icons/duotune/finance/fin002.svg",
        title: "Credit card expiring",
        info: "1 week ago",
        color: "warning",
      },
    ];

    return {
      notifications,
    };
  },
});
