
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { DrawerComponent } from "@/assets/ts/components";
import KTMenu from "@/layout/aside/Menu.vue";
import KTMenuLogo from "@/layout/aside/MenuLogo.vue";
import { isDocPage, themeName } from "@/core/helpers/documentation";
import { asideTheme } from "@/core/helpers/config";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
    KTMenuLogo,
  },
  props: {
    defaultLogo: String,
    compactLogo: String,
  },
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      DrawerComponent.reinitialization();
    });

    return {
      isDocPage,
      asideTheme,
      t,
      themeName,
    };
  },
  data: () => ({
    isToggle: false,
    stateDrawer: false,
  }),
  methods: {
    reloadPage() {
      this.$router.push("/dashboard");
    },
    handleToggleHide() {
      this.isToggle = true;
      if (this.stateDrawer) {
        this.stateDrawer = false;
      }
      if (!this.stateDrawer) {
        this.stateDrawer = true;
      }
      this.$emit("contentLeft");
    },
    handleToggleShow() {
      this.isToggle = false;
      this.stateDrawer = false;
      this.$emit("contentRight");
    },
    handleMouseEnter() {
      this.isToggle = false;
    },
    handleMouseLeave() {
      if (this.stateDrawer) {
        this.isToggle = true;
      }
    },
  },
});
